:root {
    --primary-color: #310131;
  }
  
  * {
    margin: 0;
    padding: 0;
  }
  
  #IntegraMain {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  #IntegraMain > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  #IntegraMain > div > h1 {
    font-size: 32px;
  }
  
  #IntegraContainerInfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 15px;
  }
  
  #integraTitle {
    color: #310131;
    font-size: 36px;
    font-weight: bold;
  }
  
  .card {
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
  }
  
  .card-body {
    padding: 1rem;
  }
  
  .card-body a {
    text-decoration: none;
    font-weight: 600;
    color: #310131;
  }
  
  .card-body a:hover {
    color: #8a0061;
    text-decoration: underline;
  }
  
  .card.shadow-lg {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .ratio {
    border-radius: 10px;
  }
  
  .text-center {
    margin-bottom: 20px;
  }
  
  @media (max-width: 767px) {
    #IntegraMain {
      padding: 10px;
    }
  
    #integraTitle {
      font-size: 28px;
    }
  }
  
  @media (max-width: 576px) {
    .card-body a {
      font-size: 14px;
    }
  
    #integraTitle {
      font-size: 24px;
    }
  }
  
  #integraContainerVideos{
    width: 100%;
  }