#videoPageMain {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

#videoPageContainerInfo{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
  }

  .question-item {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1rem;
  }
  
  .alternatives .form-check {
    margin-bottom: 0.5rem;
  }
  
  .modal-body {
    max-height: 400px;
    overflow-y: auto;
  }
  