#adminTitle {
    color: #310131;
    width: 100%;
    
}

#adminMain {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
}

#adminContainerTitle {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
}

#adminMain > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    flex-direction: column;
    transform: translateX(70px);

}