:root {
    --primary-color: #310131;
  }
  
  * {
    margin: 0;
    padding: 0;
  }
  
  #MenuMain {
    min-height: 100vh; /* Ocupa toda a altura da tela */
    transition: width 0.3s ease;
    background-color: var(--primary-color);
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0; /* Faz o menu ocupar toda a largura da tela */
  }
  
  #MenuContainerIconCloseMenu {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  #MenuIcon01 {
    position: absolute;
    right: 17px;
    transform: scale(2);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .MenuContainerText {
    display: flex;
    align-items: center;
  }
  
  .MenuContainerText > svg {
    margin-right: 10px;
    margin-left: 10px;
    color: white;
  }
  
  #MenuContainerIcons > div {
    margin-top: 20px;
    margin-left: 5px;
  }
  
  .MenuTextIcon {
    color: white;
    text-decoration: none;
    padding: 10px;
    display: block; /* Faz o link ocupar toda a largura disponível */
  }
  
  .MenuTextIcon:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Efeito de hover */
  }
  