#questionsPageMain {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#questionsPageContainerInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
}

table td {
    word-wrap: break-word; /* Quebra palavras longas */
    word-break: break-all; /* Quebra a linha ao ultrapassar a largura da célula */
}
